import {Component, Input, OnInit} from '@angular/core'
import { BaseService, ManagementApiService } from 'services/api'
import {FeedbackDialogComponent, FeedbackUpdateDialogComponent} from 'components/dialogs'
import { DialogService } from 'services'
import { MatDialog } from '@angular/material/dialog'
import {FeedbackManagementService} from "components/pages/feedback/feedback-management/feedback-management.service";

@Component({
  selector: 'app-management-card-feedback',
  templateUrl: 'management-card-feedback.component.html',
  styleUrls: ['management-card-feedback.component.scss'],
})

export class ManagementCardFeedbackComponent {

  @Input() feedback: any
  @Input() users: any

  feedbackAnswers: any;
  feedbackAnswersMessages: undefined;
  feedbackAnswersMessagesFile: undefined;
  feedbackAnswersFiles: undefined;
  open = false

  stars: number[] = [1, 2, 3, 4, 5];

  constructor(
    private baseService: BaseService,
    private managementService: ManagementApiService,
    private feedbackManagementService: FeedbackManagementService,
    private dialogService: DialogService,
    private dialog: MatDialog
  ) {
  }

  showFeedback() {
    console.log(this.feedback);
    if (!this.feedbackAnswers) {
      this.getFeedback()
    }
    this.open = !this.open
  }

  viewFile(url) {
    window.open(url)
  }

  sendAnswerFeedback(feedbackId) {
    let data = {
      'title': 'Ответить',
      'feedbackId': feedbackId,
      showForm: false,
      managementAnswer: true,
    }
    const dialogRef = this.dialog.open(FeedbackDialogComponent, {data});

    dialogRef.afterClosed().subscribe( () => {
      this.getFeedback()
    })
  }

  updateFeedback(feedback) {
    feedback.moderator = this.feedbackAnswers.moderator
    feedback.users = this.users
    let data = {
      'title': 'Редактировать',
      'feedback': feedback,
    }
    const dialogRef = this.dialog.open(FeedbackUpdateDialogComponent, {data});
    dialogRef.afterClosed().subscribe((resp) => {
      if (resp) {
        this.feedbackManagementService.$updated.next(resp)
      }
    })
  }

  getFeedback() {
    this.managementService.managementFeedbackGet(this.feedback.id).subscribe( (response: any) => {
      this.feedbackAnswers = response;
      this.managementService.managementFeedbackMessageGet(response.id).subscribe( (response: any) => {
        this.feedbackAnswersMessages = response
      })
    })
    this.managementService.managementFeedbackFileGet(this.feedback.id).subscribe( (response: any) => {
      this.feedbackAnswersFiles = response
    })
  }

}
