<app-header></app-header>
<div class="outer">
  <div class="workspace"
       #workspace
       scrollButtonListener
       [ngClass]="{'mobile_menu': extraService.showUserMobile}"
  >
    <app-side-menu></app-side-menu>
    <section class="content" [@show_preview]="showPrev" [ngClass]="{'open_mobile_menu': extraService.showUserMobile}"
             infiniteScroll
             [infiniteScrollDistance]="2"
             [infiniteScrollThrottle]="50"
             [infiniteScrollDisabled]="endScroll"
             [scrollWindow]="false"
             (scrolled)="paginationOn()">

      <div
        class="document_list"
      >
        <div class="document" *ngFor="let document of documents" (click)="goDocument(document)">
          <div class="new_doc" *ngIf="!document.is_view">NEW</div>
          <div class="new_message" *ngIf="document.nonviewed_messages">
            <div class="icon"></div>
            <span>+{{document.nonviewed_messages}}</span>
          </div>
          <div class="document_image" [ngStyle]="{backgroundImage: 'url(' + (document.preview !== '' ? (document.preview) : '') + ')'}"></div>
          <div class="document_title">
            <span>{{document.title}}</span>
            <div class="dead_date" *ngIf="document.deadline && !document.result">
              <span class="desc">Дедлайн теста: {{document.deadline | dateFormat: 'mediumDate'}} до {{document.deadline | dateFormat: 'shortTime'}}</span>
            </div>
            <div class="dead_date" *ngIf="document.end_date">
              <span class="desc black">Курс доступен до: {{document.end_date | dateFormat: 'mediumDate'}} </span>
            </div>
            <div class="result" *ngIf="document.result !== null" [ngStyle]="{color: document.before_deadline ? 'black' : 'grey'}">Результат: {{ document.result }}%
              <div
                class="bullet"
                [ngClass]="{
                  gray: document.result === 0,
                  crimson: document.result >= 1 && document.result <= 49,
                  red: document.result >= 50 && document.result <= 79,
                  yellow: document.result >= 80 && document.result <= 89,
                  light_green: document.result >= 90 && document.result <= 99,
                  green: document.result === 100
                }"
              >
              </div>
            </div>
            <div class="attempts">Попытки: {{document.spent_attempts + '/' + document.number_attempts}}</div>
          </div>
        </div>
      </div>

    </section>
    <app-scroll-to-top [workspace]="workspace"></app-scroll-to-top>
  </div>

</div>
