<app-header></app-header>
<div class="outer">
  <div class="workspace"
       #workspace
       scrollButtonListener
       [ngClass]="{'mobile_menu': extraService.showUserMobile}">
    <app-side-menu></app-side-menu>

    <section class="content" [ngClass]="{'open_mobile_menu': extraService.showUserMobile}" [@show_preview]="showPrev"
             infiniteScroll
             [infiniteScrollDistance]="2"
             [infiniteScrollThrottle]="50"
             [infiniteScrollDisabled]="endScroll"
             [scrollWindow]="false"
             (scrolled)="paginationOn()">

      <div class="back_button" *ngIf="viewBack" (click)="goBack()">
        <div class="back_icon"></div>
        <div class="back_title">Назад</div>
      </div>

      <div class="title_business">Кликни на тему для перехода</div>

      <form class="form_search" [formGroup]="formSearch" (ngSubmit)="searchForm()" enctype="multipart/form-data">
        <input placeholder="Введите запрос для поиска по бизнес кодексу" formControlName="query">
        <button class="search_button" type="submit"></button>
      </form>

      <div class="container_document_folder">
        <ng-container *ngFor="let folder of folders">
          <div class="document_folder" (click)="goFolder(folder.id)">
            <div class="count" *ngIf="folder.count !== 0">+{{folder.count}}</div>
            <img src="{{folder.preview}}">

            <div class="container_doc_absolute" [id]="folder.id">
              <div class="document_list" *ngFor="let document of documents">
                <div class="doc_block" (click)="goDocument(document)">
                  {{document.title}}
                  <div class="new_doc" *ngIf="!document.is_view">NEW</div>
                </div>
              </div>
            </div>

          </div>
        </ng-container>
      </div>

      <div class="container_document" *ngIf="this.searched">
        <div class="document" *ngFor="let document of documents">
          <div class="document_button" (click)="goDocument(document)">
            {{document.title}}
          </div>
        </div>
      </div>

    </section>

    <app-scroll-to-top [workspace]="workspace"></app-scroll-to-top>

  </div>

</div>
