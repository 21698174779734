<div class="header_osn">
  <div class="logo_container">
    <div class="logo"></div>
    <div class="logo"></div>
    <div class="logo"></div>
    <div class="logo"></div>
    <!-- <div class="logo"></div> -->
  </div>
</div>

<div class="outer auth">
  <div class="circle">
    <form class="form_container" [@show_form]="showForm" [formGroup]="auth" (ngSubmit)="onSubmit()">
      <div class="login_input">
        <div class="login">Логин (email)</div>
        <input formControlName="email" type="email">
        <div *ngIf="auth.controls['email'].invalid && auth.controls['email'].touched" class="error_text">
              <span *ngIf="auth.controls['email'].errors.required">
                Поле «Логин» обязательно для заполнения.
              </span>
              <span *ngIf="auth.controls['email'].errors.email">
                Введен некорректный email.
              </span>
        </div>
      </div>
      <div class="password_input">
        <div class="password">Пароль</div>
        <input
          type="{{passwFieldType}}"
          formControlName="password"
        >
        <i
          class="view-switcher"
          (click)="showPassw()"
        >
          <div class="close_eye">
            <img src="/assets/images/icons/close_eye.png" *ngIf="passwFieldType === 'password'">
            <img src="/assets/images/icons/eye.png" *ngIf="passwFieldType === 'text'">
          </div>
        </i>
      </div>
      <div *ngIf="auth.controls['password'].invalid && auth.controls['password'].touched" class="error_text">
        <span *ngIf="auth.controls['password'].errors.minlength">
          Поле «Пароль» должно содержать не менее 6 символов.
        </span>
        <span *ngIf="auth.controls['password'].errors.required">
          Поле «Пароль» обязательно для заполнения.
        </span>
      </div>
<!--      <div class="consent_group">-->
<!--        <mat-checkbox formControlName="agreement"></mat-checkbox>-->
<!--        <div class="consent">-->
<!--          Согласие на-->
<!--          <span (click)="onRules()">обработку данных</span>-->
<!--        </div>-->
<!--      </div>-->




      <div class="button_group">
        <button class="come_in_button" [disabled]="auth.invalid" type="submit">Войти по логину</button>
      </div>
    </form>

  </div>

</div>
