<div class="container-current-rating">

  <div class="container-history-rating">
    <div class="button_show" (click)="showLeaders()">{{open == false ? 'открыть лидеров портала' : 'закрыть'}}</div>
    <ng-container *ngIf="open">
      <app-card-rating *ngFor="let leaders of leaders" [history]="leaders"></app-card-rating>
    </ng-container>
  </div>

  <div class="container-mat-toggle">
    <div class="container-toggle-mobile">
      <div class="text-mat-toggle-mobile">Показать рейтинг текущего месяца</div>
      <div class="text-mat-toggle-mobile">Показать рейтинг с начала года</div>
    </div>
    <div class="text-mat-toggle">Показать рейтинг текущего месяца</div>
    <mat-slide-toggle (change)="checkRating($event)" [checked]="this.checked"></mat-slide-toggle>
    <div class="text-mat-toggle">Показать рейтинг с начала года</div>
  </div>

  <div class="input_container">
    <input placeholder="Введите фамилию/имя" [(ngModel)]="searchString" (keydown.enter)="searchUser()">
    <div class="input_button_container">
      <div class="input_button delete" *ngIf="!!searchString" (click)="clearSearchUser()"></div>
      <div class="input_button search" (click)="searchUser()"></div>
    </div>
  </div>

  <div class="select_container" *ngIf="menuOpenFilter">
    <form [formGroup]="formFilter" class="form_filter" *ngIf="formFilter">

      <mat-select class="custom_select for_management" placeholder="Бренд" multiple (selectionChange)="searchUser()" formControlName="brand">
        <mat-option
          *ngFor="let brand of brands"
          [value]="brand.id">
          {{ brand.title }}
        </mat-option>
      </mat-select>

      <mat-select class="custom_select for_management" placeholder="Должность" multiple (selectionChange)="searchUser()" formControlName="position">
        <mat-option
          *ngFor="let position of positions"
          [value]="position.id">
          {{ position.title }}
        </mat-option>
      </mat-select>

      <mat-select class="custom_select for_management" placeholder="Канал" multiple (selectionChange)="searchUser()" formControlName="channel">
        <mat-option
          *ngFor="let channel of channels"
          [value]="channel.id">
          {{ channel.title }}
        </mat-option>
      </mat-select>

      <mat-select class="custom_select for_management" placeholder="Регион" multiple (selectionChange)="searchUser()" formControlName="region">
        <mat-option
          *ngFor="let region of regions"
          [value]="region.id">
          {{ region.title }}
        </mat-option>
      </mat-select>

      <mat-select class="custom_select for_management" placeholder="Штат" multiple (selectionChange)="searchUser()" formControlName="state">
        <mat-option
          *ngFor="let state of states"
          [value]="state.id">
          {{ state.title }}
        </mat-option>
      </mat-select>
    </form>
    <div class="button-container">
      <div class="button" (click)="searchUser()">Применить</div>
    </div>
  </div>

  <div class="text-title-rating">
    {{this.titleRating}}
  </div>

  <div
    class="table_container"
    infiniteScroll
    [infiniteScrollDisabled]="endScroll"
    [scrollWindow]="false"
    (scrolled)="paginationOn()"
  >
    <div class="table_header">
      <div class="td number_header">Место</div>
      <div class="td">Баллы</div>
      <div class="td photo"></div>
      <div class="td more">ФИО</div>
      <div class="td more">Регион</div>
      <div class="td more">Бренд</div>
      <div class="td"></div>
    </div>

    <div class="tr" *ngIf="this.currentUser">
      <div class="button-table mobile"
           (click)="getUser(this.currentUser.id, this.currentUser.name, this.currentUser.surname, this.currentUser.email, this.currentUser.city, this.currentUser.regions, this.currentUser.position, this.currentUser.channel, this.currentUser.brands)">
      </div>
      <div class="td number">
        <div class="icon-number"
             [ngClass]="{
              'icon-one': this.currentUser.number === 1,
              'icon-two': this.currentUser.number === 2,
              'icon-three': this.currentUser.number === 3
              }">
        </div>
        <ng-container *ngIf="this.currentUser.number > 3">{{this.currentUser.number}}</ng-container>
      </div>
      <div class="td">{{this.currentUser.scores}}</div>
      <div class="td photo">
        <div class="profile_img" [ngStyle]="{'backgroundImage': 'url(' + (this.currentUser.photo !== null ? (this.currentUser.photo) : '/assets/images/icons/avatar_none.png') + ')'}"></div>
      </div>
      <div class="td more">
        {{this.currentUser.surname}} {{this.currentUser.name}}
      </div>
      <div class="td more brand">
        <span *ngFor="let region of this.currentUser.regions">{{ region }}</span>
      </div>
      <div class="td more brand">
        <span *ngFor="let brand of this.currentUser.brands">{{ brand }}</span>
      </div>
      <div class="td no_show">
        <div class="button-table"
             (click)="getUser(this.currentUser.id, this.currentUser.name, this.currentUser.surname, this.currentUser.email, this.currentUser.city, this.currentUser.regions, this.currentUser.position, this.currentUser.channel, this.currentUser.brands)">
        </div>
      </div>
    </div>

    <div class="tr" *ngFor="let user of users; let i = index"
         [ngClass]="{
              'gold': user.number < 4
              }">
      <div class="button-table mobile"
           (click)="getUser(user.id, user.name, user.surname, user.email, user.city, user.regions, user.position, user.channel, user.brands)">
      </div>
      <div class="td number">
        <div class="icon-number"
             [ngClass]="{
              'icon-one': user.number === 1,
              'icon-two': user.number === 2,
              'icon-three': user.number === 3
              }">
        </div>
        <ng-container *ngIf="user.number > 3">{{user.number}}</ng-container>
      </div>
      <div class="td">{{user.scores}}</div>
      <div class="td photo">
        <div class="profile_img" [ngStyle]="{'backgroundImage': 'url(' + (user.photo !== null ? (user.photo) : '/assets/images/icons/avatar_none.png') + ')'}"></div>
      </div>
      <div class="td more">
        {{user.surname}} {{user.name}}
      </div>
      <div class="td more brand">
        <span *ngFor="let region of user.regions">{{ region }}</span>
      </div>
      <div class="td more brand">
        <span *ngFor="let brand of user.brands">{{ brand }}</span>
      </div>
      <div class="td no_show">
        <div class="button-table"
             (click)="getUser(user.id, user.name, user.surname, user.email, user.city, user.regions, user.position, user.channel, user.brands)">
        </div>
      </div>
    </div>
  </div>

</div>

