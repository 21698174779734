import { Component, Input } from '@angular/core'
import { BaseService } from 'services/api'
import { AlertDialogComponent, FeedbackDialogComponent, FeedbackRatingDialogComponent } from 'components/dialogs'
import { DialogService } from 'services'
import { MatDialog } from '@angular/material/dialog'

@Component({
  selector: 'app-feedback-card',
  templateUrl: 'feedback-card.component.html',
  styleUrls: ['feedback-card.component.scss'],
})

export class FeedbackCardComponent {

  @Input() feedback: any

  feedbackAnswers: undefined;
  feedbackAnswersMessages: undefined;
  feedbackAnswersFiles: undefined;
  open = false

  stars: number[] = [1, 2, 3, 4, 5];

  offset: number = 0;
  limit: number = 10;

  constructor(
    private baseService: BaseService,
    private dialogService: DialogService,
    private dialog: MatDialog
  ) {
  }

  showFeedback() {
    console.log(this.feedback);
    if (!this.feedbackAnswers) {
      this.baseService.feedbackGet(this.feedback.id).subscribe( (response: any) => {
        this.feedbackAnswers = response;
        this.feedbackAnswersMessages = response.messages
        this.feedbackAnswersFiles = response.files
      })
    }
    this.open = !this.open
  }

  viewFile(url) {
    window.open(url)
  }

  sendAnswerFeedback(feedbackId) {
    let data = {
      'title': 'Запрос',
      'feedbackId': feedbackId,
      showForm: false,
    }
    const dialogRef = this.dialog.open(FeedbackDialogComponent, {data});

    dialogRef.afterClosed().subscribe( () => {
      window.location.reload();
    })
  }

  finishFeedback(feedbackId, answered) {
    if(!answered) {
      let data = {
        title: 'Запрос решен',
        confirmButtonText: 'OK'
      }
      const dialogRef = this.dialog.open(AlertDialogComponent, {data, disableClose: true});

      this.baseService.feedbackFinish(feedbackId, null, true).subscribe( () => {})

      dialogRef.afterClosed().subscribe( () => {
        window.location.reload();
      })
    } else {
      let data = {
        'feedbackId': feedbackId
      }

      const dialogRef = this.dialog.open(FeedbackRatingDialogComponent, {data, disableClose: true});

      dialogRef.afterClosed().subscribe( () => {
        window.location.reload();
      })
    }
  }
}
