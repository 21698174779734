<app-header></app-header>
<div class="outer" *ngIf="extraService.profileInfo" id="top">
  <div class="workspace personal"
       #workspace
       scrollButtonListener
       [ngClass]="{'mobile_menu': extraService.showUserMobile}">
    <app-side-menu></app-side-menu>

    <div class="container-feedback" [ngClass]="{'open_mobile_menu': extraService.showUserMobile}" [@show_preview]="showPrev">
      <div class="button-top">
        <div class="button" (click)="newFeedback()">Новый запрос</div>
      </div>
      <div class="answer-container custom_scroll"
           infiniteScroll
           [infiniteScrollDisabled]="endScroll"
           [scrollWindow]="false"
           (scrolled)="paginationOn()">
        <app-feedback-card *ngFor="let card of feedbackList" [feedback]="card"></app-feedback-card>
      </div>
    </div>
    <app-scroll-to-top [workspace]="workspace"></app-scroll-to-top>
  </div>

</div>
